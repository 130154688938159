import logo from './logo.svg';
import axios from 'axios';
import './App.css';
import { SectionSelector } from './SectionSelector.tsx';
import { Box, Container } from '@mui/material';
import { Header } from './Header.tsx';
import { SubHeader } from './SubHeader.tsx';
import { Breadcrumb } from './Breadcrumb.tsx';




function App() {
  return (
    <Box sx={{ width: '100%', height: '100%', backgroundColor: '#FAFAFA' }}>
      {/*<Header />
      <SubHeader />
      <Breadcrumb />*/}
      <SectionSelector />
    </Box>
  );
}

export default App;
