import { Icon } from "@iconify/react";
import { Button, Card, MenuItem, Select, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { CardHeader } from "./CardHeader.tsx";


export const ConfigurationSelection = ({ configurations, selectedConfiguration, setSubmitType, downloadConfigurationAreas }) => {

    return (
        <Card variant="outlined" sx={{ padding: 2, marginX: 2, flex: 1 }}>
            <CardHeader iconName={"fa:arrow-up"} title={"Carica configurazione"} />
            <Box display="flex" flexDirection={"row"} sx={{ paddingTop: 2, paddingBottom: 1 }} >
                <Select value={selectedConfiguration} size='small' fullWidth onChange={(e) => { downloadConfigurationAreas(e) }}>
                    {configurations.map((e: any, i) => {
                        return <MenuItem key={i} value={e.id}>{e.name}</MenuItem>
                    })}
                </Select>

                {selectedConfiguration && <Button sx={{ marginLeft: 1, minWidth: 10, width: 60, maxWidth: 60 }} color="success" type="submit" onClick={() => { setSubmitType("update-configuration") }}><Icon icon="fa:refresh" /></Button>}
                {selectedConfiguration && <Button sx={{ marginLeft: 1, minWidth: 10, width: 60, maxWidth: 60 }} color="error" type="submit" onClick={() => { setSubmitType("delete-configuration") }}><Icon icon="fa:trash" /></Button>}
            </Box>
        </Card>
    )
}

