import { Icon } from "@iconify/react";
import { Button, Card, CardHeader, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";


export const Footer = ({ reset, setSubmitType }) => {

    return (
        <Box width={"42%"} position="fixed" bottom={0}>
            <Card variant="outlined" sx={cardStyle}>
                <Box sx={cardHeaderStyle} display='flex' flexDirection={'row'} alignItems='center'>
                    <Icon icon="fa:rocket" />
                    <Typography sx={titleProps}>Azioni</Typography>
                </Box>
                <Box margin={2}>
                    <Button color="error" variant="contained" onClick={() => reset()}>Reset</Button>
                    <Button sx={{ marginLeft: 2 }} type="submit" color="success" variant="contained" onClick={() => setSubmitType("extract")}>Invia</Button>
                </Box>
            </Card>
        </Box>
    )
}

const cardHeaderStyle = {
    paddingY: 1,
    paddingX: 2,
    fontSize: '14px',
    fontFamily: 'sans-serif',
    backgroundColor: '#EEE',
    fontColor: '#000',
    fontWeight: 100
}

const cardStyle = {
    marginLeft: 2,
    marginRight: 2,
    fontSize: '14px',
    fontFamily: 'sans-serif',
    backgroundColor: '#FFF',
    fontColor: '#000',
    fontWeight: 100
}


const titleProps = {
    fontSize: '15px',
    fontFamily: 'sans-serif',
    fontColor: '#000',
    marginLeft: 1,
    fontWeight: 100,
}
