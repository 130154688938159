import { Icon } from "@iconify/react";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";


export const CardHeader = ({ iconName, title }) => {

    return (
        <Box sx={cardHeaderStyle} display='flex' flexDirection={'row'} alignItems='center'>
            <Icon icon={iconName} />
            <Typography sx={titleProps}>{title}</Typography>
        </Box>
    )
}

const cardHeaderStyle = {
    paddingY: 1,
    paddingX: 2,
    fontSize: '14px',
    fontFamily: 'sans-serif',
    backgroundColor: '#EEE',
    fontColor: '#000',
    marginTop: -2,
    marginX: -2,
    fontWeight: 100
}

const titleProps = {
    fontSize: '15px',
    fontFamily: 'sans-serif',
    fontColor: '#000',
    marginLeft: 1,
    fontWeight: 100,
}
