import { Icon } from "@iconify/react";
import { Card, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { CardHeader } from "./CardHeader.tsx";


export const FileSelection = ({ onFileChange, register }) => {

    return (
        <Box margin={2}>
            <Card variant="outlined">
                <CardHeader/>
                <Box sx={cardHeaderStyle} display='flex' flexDirection={'row'} alignItems='center'>
                    <Icon icon="fa:upload" />
                    <Typography sx={titleProps}>Documenti da esaminare</Typography>
                </Box>
                <Box margin={2}>
                    <input {...register('files')} multiple type="file" name="file" onChange={(e) => { onFileChange(e) }} />
                </Box>
            </Card>
        </Box>
    )
}

const cardHeaderStyle = {
    paddingY: 1,
    paddingX: 2,
    fontSize: '14px',
    fontFamily: 'sans-serif',
    backgroundColor: '#EEE',
    fontColor: '#000',
    fontWeight: 100
}

const titleProps = {
    fontSize: '15px',
    fontFamily: 'sans-serif',
    fontColor: '#000',
    marginLeft: 1,
    fontWeight: 100,
}
