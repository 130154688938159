import { Icon } from "@iconify/react";
import { Button, Card, Grid, IconButton, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { CardHeader } from "./CardHeader.tsx";


export const WordSearch = ({ wordsToSearch, setWordsToSearch, addWords, register }) => {

    const deleteArea = (index) => {
        let newAreas = [...wordsToSearch]
        newAreas.splice(index, 1)
        setWordsToSearch(newAreas)
    }

    return (
        <Card sx={{ padding: 2, margin: 2 }} >
            <CardHeader iconName={'fa:search'} title={'Prefissi da cercare nell\'intero documento'} />
            {wordsToSearch && wordsToSearch.length > 0 ? wordsToSearch.map((e, i) => (
                <Box
                    sx={{
                        '& > :not(style)': { m: 1 },
                        marginTop: 1,
                        display: 'flex'
                    }}
                    key={i}
                    justifyContent='center'
                    alignItems='center'
                >
                        <TextField key={1} sx={{display: 'none'}} {...register("left")} name="left" defaultValue={0} />
                        <TextField key={2} sx={{display: 'none'}} {...register("top")} name="top" defaultValue={0} />
                        <TextField key={3} sx={{display: 'none'}} {...register("width")} name="width" defaultValue={0} />
                        <TextField key={4} sx={{display: 'none'}} {...register("height")} name="height" defaultValue={0} />
                        <TextField key={5} sx={{display: 'none'}} {...register("pageNumber")} name="pageNumber" defaultValue={1} />
                        <TextField key={6} sx={{display: 'none'}} {...register("type")} name="type" defaultValue={"research"} />
                        <TextField key={7} sx={{flex: 8}} required label={`Nome`} name="name" onChange={(e) => {
                            let newAreas = wordsToSearch
                            newAreas[i].name = e.target.value
                            setWordsToSearch(newAreas)
                        }}
                            defaultValue={wordsToSearch[i].name ? wordsToSearch[i].name : ""}
                        />
                        <TextField key={8} sx={{flex: 12}} required label={"Prefisso"} name="word_search" onChange={(e) => {
                            let newAreas = wordsToSearch
                            newAreas[i].word_search = e.target.value
                            setWordsToSearch(newAreas)
                        }}
                            defaultValue={wordsToSearch[i].word_search ? wordsToSearch[i].word_search : ""}
                        />
                        <TextField key={9} sx={{flex: 5}} required label={"Occorrenza"} name="occurrence" onChange={(e) => {
                            let newAreas = wordsToSearch
                            newAreas[i].occurrence = Number(e.target.value)
                            setWordsToSearch(newAreas)
                        }}
                            defaultValue={wordsToSearch[i].occurrence ? wordsToSearch[i].occurrence : ""} />
                        <TextField key={10} sx={{flex: 4}} required label={"N° parole"} name="word_number" onChange={(e) => {
                            let newAreas = wordsToSearch
                            newAreas[i].word_number = Number(e.target.value)
                            setWordsToSearch(newAreas)
                        }}
                            defaultValue={wordsToSearch[i].word_number ? wordsToSearch[i].word_number : ""}
                        />
                           <IconButton color="error" sx={{height: 35, width: 35}} onClick={(i) => deleteArea(i)}>
                            <Icon icon="fa:trash"></Icon>
                        </IconButton>
                </Box>
            )) :
                <></>
            }
            <Button sx={{ marginTop: 2 }} variant="contained" onClick={() => addWords()}>Aggiungi parola da cercare</Button>
        </Card>

    )
}

const cardHeaderStyle = {
    paddingY: 1,
    paddingX: 2,
    fontSize: '14px',
    fontFamily: 'sans-serif',
    backgroundColor: '#EEE',
    fontColor: '#000',
    fontWeight: 100
}

const titleProps = {
    fontSize: '15px',
    fontFamily: 'sans-serif',
    fontColor: '#000',
    marginLeft: 1,
    fontWeight: 100,
}
