import { Icon } from "@iconify/react";
import { Card, CardHeader, CircularProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";


export const EmptyPreview = ({previewLoading}) => {

    return (
        <Box sx={boxStyle}>
            <Card variant="outlined" sx={cardStyle}>
                {previewLoading?
                <>
                   <CircularProgress/>
                <Typography marginTop={2}>
                Anteprima in arrivo
                </Typography>
                </>
             :
                <Typography>
                    Anteprima non disponibile.
                    <br/> 
                Selezionare gli elementi da esaminare.
                </Typography>
                }

            </Card>
        </Box>
    )
}

const boxStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    height: '80vh'
}

const cardStyle = {
    paddingY: 1,
    paddingX: 2,
    margin: 2,
    fontSize: '14px',
    fontFamily: 'sans-serif',
    fontColor: '#000',
    width: 500,
    fontWeight: 100,
    display: 'flex',
    justifyContent:'center',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center'
}

const titleProps = {
    fontSize: '15px',
    fontFamily: 'sans-serif',
    fontColor: '#000',
    marginLeft: 1,
    fontWeight: 100,
}
