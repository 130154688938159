import { AreaSelector, IArea, IAreaRendererProps } from '@bmunozg/react-image-area'
import { Button, TextField, Card, Grid, CardHeader, Typography, Select, MenuItem, Snackbar, Alert, Slide, Modal, CircularProgress } from '@mui/material';
import { Box, Stack } from '@mui/system';
import axios from 'axios';
import React from 'react';
import { useState } from 'react';
import { ConvertedImage } from './interface/ConvertedImage';
import { SectionArea } from './interface/SectionArea';
import { FileSelection } from './FileSelection.tsx';
import { EmptyPreview } from './EmptyPreview.tsx';
import { Footer } from './Footer.tsx';
import { ConfigurationSelection } from './ConfigurationSelection.tsx';
import { ConfigurationSave } from './ConfigurationSave.tsx';
import { AreaSelection } from './AreaSelection.tsx';
import { WordSearch } from './WordSearch.tsx';
import { useForm, useFieldArray } from 'react-hook-form';

export const SectionSelector = () => {
    const [areas, setAreas] = useState<SectionArea[]>([]);
    const [previews, setPreviews] = useState<ConvertedImage[]>([])
    const [wordsToSearch, setWordsToSearch] = useState<SectionArea[]>([])
    const [submitType, setSubmitType] = useState<string>()
    const [selectedConfiguration, setSelectedConfiguration] = useState("")
    const [previewLoading, setPreviewLoading] = useState(false)
    const [isElaborating, setIsElaborating] = useState(false)
    const [updateConfigurations, setUpdateConfigurations] = useState(true)

    const [snackbar, setSnackbar] = useState({
        isOpen: false,
        severity: "info",
        message: ""
    })

    const { control, register, handleSubmit, watch, setValue, formState: { errors } } = useForm();
    const { remove } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: "areas"
    });

    const showSuccess = (message) => {
        setSnackbar({
            isOpen: true,
            message: message,
            severity: "success"
        })
    }

    const showError = (message) => {
        setSnackbar({
            isOpen: true,
            message: message,
            severity: "error"
        })
    }

    const hideSnackbar = () => {
        setSnackbar({
            ...snackbar,
            isOpen: false,
        })
    }

    React.useEffect(() => {
        if (updateConfigurations) {
            getConfigurations()
            setUpdateConfigurations(false)
        }
    }, [updateConfigurations])

    const [configurations, setConfigurations] = useState([])
    const getConfigurations = () => {
        axios.get(process.env.REACT_APP_OCR_SERVICE + "/configurations").then((response) => {
            setConfigurations(response.data.data)
        }).catch((error) => {
            showError("Errore interno nella ricezione delle configurazioni")
        })
    }

    const [configurationAreas, setConfigurationAreas] = useState([])
    const downloadConfigurationAreas = (e) => {
        setSelectedConfiguration(e.target.value)
        axios.get(`${process.env.REACT_APP_OCR_SERVICE}/configurations/${e.target.value}/areas`).then((response) => {
            setConfigurationAreas(response.data.data)
        }).catch(() => {
            showError("Errore interno nella ricezione delle aree configurate")
        })
    }

    React.useEffect(() => {
        if (configurationAreas.length > 0) {
            const filteredAreas = configurationAreas.filter((a: any) => a.type === "selection").map((a: any) => {
                a.x = a.left * 100
                a.y = a.top * 100
                a.width = a.width * 100
                a.height = a.height * 100
                a.unit = "%"
                return a
            })
            setAreas(filteredAreas)
            const searchedAreas = configurationAreas.filter((a: any) => a.type === "research").map((a: any) => {
                a.x = a.left * 100
                a.y = a.top * 100
                a.width = a.width * 100
                a.height = a.height * 100
                a.unit = "%"
                return a
            })
            setWordsToSearch(searchedAreas)
        }
    }, [configurationAreas])

    const [multipleImages, setMultipleImages] = useState([]);
    const onFileChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            const imageArray: any = Array.from(event.target.files).map((file: any) =>
                file
            );
            setMultipleImages((prevImages) => prevImages.concat(imageArray));

            if (event.target.files[0].type === "application/pdf") {
                showPdfPreview(event.target.files)
            } else {
                showImagePreview(event.target.files)
            }
        }
    }

    const showPdfPreview = (files) => {
        let formData = new FormData()
        for (let index = 0; index < files.length; index++) {
            const file = files[index]
            formData.append("file", file)
        }
        setPreviewLoading(true)
        axios.post(process.env.REACT_APP_OCR_SERVICE + '/conversion/convert-pdf-to-images', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((response) => {
            setPreviewLoading(false)
            setPreviews(response.data.data)
            showSuccess("Anteprima arrivata!")
        })
    }

    const showImagePreview = (files) => {
        const url = URL.createObjectURL(files[0])
        const image = new Image();
        image.src = url
        image.onload = function () {
            setPreviews([
                {
                    src: url,
                    type: 'url',
                    width: image.width,
                    height: image.height,
                    page: 1
                }
            ])
        }
    }



    const reset = () => {
        setAreas([])
        setWordsToSearch([])
        setConfigurationAreas([])
        setSelectedConfiguration("")
    }

    const onChangeHandler = (selectedAreas: any[], preview) => {
        let newAreas: SectionArea[] = areas.filter(a => a.page !== preview.page)
        let oldAreas: SectionArea[] = areas.filter(a => a.page === preview.page)
        for (let index = 0; index < selectedAreas.length; index++) {
            const area = selectedAreas[index]
            const newArea = {
                ...area,
                name: oldAreas[index] ? oldAreas[index].name : "",
                page:
                    preview.page,
                pageWidth: preview.width,
                pageHeight: preview.height,
                occurrence: oldAreas[index] ? oldAreas[index].occurrence : "",
                word_search: oldAreas[index] ? oldAreas[index].word_search : "",
                word_number: oldAreas[index] ? oldAreas[index].word_number : "",
                type: 'selection'
            }
            newAreas.push(newArea)
        }
        setAreas(newAreas);
    }

    const areaInfo = (areaProps: IAreaRendererProps) => {
        if (!areaProps.isChanging) {
            return (
                <Box bgcolor={'rgba(0,0,0,0.1)'} width={"100%"} height={"100%"} display="flex" alignItems="center" justifyContent="center" key={areaProps.areaNumber}>
                    {areaProps.areaNumber}
                </Box>
            );
        }
    };

    const addWords = () => {
        const newWordsToSearch = [...wordsToSearch]
        const newSection = {
            x: 0,
            y: 0,
            width: 0,
            height: 0,
            name: '',
            page: 1,
            pageWidth: 0,
            pageHeight: 0,
            occurrence: null,
            word_search: null,
            word_number: null,
            type: "research"
        } as SectionArea
        newWordsToSearch.push(newSection)
        setWordsToSearch(newWordsToSearch)
    }

    const appendAreas = () => {
        let formData = new FormData();
        for (const key of Object.keys(multipleImages)) {
            formData.append('files', multipleImages[key]);
        }
        for (const key of Object.keys(areas)) {
            formData.append('pageNumber', areas[key].page);
            formData.append('left', (areas[key].x / 100).toString());
            formData.append('top', (areas[key].y / 100).toString());
            formData.append('width', (areas[key].width / 100).toString());
            formData.append('height', (areas[key].height / 100).toString());
            formData.append('pageWidth', areas[key].pageWidth);
            formData.append('pageHeight', areas[key].pageHeight);
            formData.append('name', areas[key].name);
            formData.append('type', areas[key].type);
            formData.append('occurrence', areas[key].occurrence ? areas[key].occurrence : "");
            formData.append('word_search', areas[key].word_search ? areas[key].word_search : "");
            formData.append('word_number', areas[key].word_number ? areas[key].word_number : "");
        }
        for (const key of Object.keys(wordsToSearch)) {
            formData.append('pageNumber', wordsToSearch[key].page);
            formData.append('left', wordsToSearch[key].x);
            formData.append('top', wordsToSearch[key].y);
            formData.append('width', wordsToSearch[key].width);
            formData.append('height', wordsToSearch[key].height);
            formData.append('name', wordsToSearch[key].name);
            formData.append('type', wordsToSearch[key].type);
            formData.append('occurrence', wordsToSearch[key].occurrence ? wordsToSearch[key].occurrence : "");
            formData.append('word_search', wordsToSearch[key].word_search ? wordsToSearch[key].word_search : "");
            formData.append('word_number', wordsToSearch[key].word_number ? wordsToSearch[key].word_number : "");
        }
        return formData
    }

    const extract = () => {

        let formData = appendAreas();
        setIsElaborating(true)
        axios.post(process.env.REACT_APP_OCR_SERVICE + '/ocr/extract-text-from-sections', formData, {
            responseType: "blob",
            headers: {
                "Content-type": "multipart/form-data"
            }
        }).then((response) => {
            const url = window.URL.createObjectURL(response.data)
            window.open(url)
            setIsElaborating(false)
            showSuccess(response.data.message)
        }).catch(async (error) => {
            setIsElaborating(false)
            let response = await error.response.data.text();
            response = JSON.parse(response)
            showError(response.message)
        })
    }

    const saveConfiguration = () => {
        let formData = appendAreas()
        formData.append('configuration_name', watch("configuration_name"));
        axios.post(process.env.REACT_APP_OCR_SERVICE + '/configurations', formData, {
            responseType: "json",
            headers: {
                "Content-type": "application/json"
            }
        }
        ).then((response) => {
            showSuccess("Configurazione salvata")
            setUpdateConfigurations(true)
        }).catch((error) => {
            showError(error.response.data.message)
        })
    }

    const updateConfiguration = () => {
        let formData = appendAreas()
        formData.append('configuration_name', watch("configuration_name"));
        axios.put(process.env.REACT_APP_OCR_SERVICE + `/configurations/${selectedConfiguration}`, formData,
            {
                responseType: "json",
                headers: {
                    "Content-type": "application/json"
                }
            }
        ).then((response) => {
            showSuccess("Configurazione aggiornata")
            setUpdateConfigurations(true)
        }).catch(() => {
            showError("Errore nell'aggiornamento")
        })
    }

    const deleteConfiguration = () => {

        axios.delete(process.env.REACT_APP_OCR_SERVICE + `/configurations/${selectedConfiguration}`,
            {
                responseType: "json",
                headers: {
                    "Content-type": "application/json"
                }
            }
        ).then((response) => {
            showSuccess("Configurazione eliminata")
            setUpdateConfigurations(true)
        }).catch(() => {
            showError("Errore nell'aggiornamento")
        })
    }

    const submit = () => {
        if (submitType === "extract") {
            extract()
        } else if (submitType === "save-configuration") {
            saveConfiguration()
        } else if (submitType === "update-configuration") {
            updateConfiguration()
        } else if (submitType === "delete-configuration") {
            deleteConfiguration()
        }
    }

    return (
        <>
            <form encType='multipart/form-data' onSubmit={handleSubmit(submit)} method="post" target='_'>
                <Grid height="100vh" container overflow="clip">
                    <Grid item xs={5} flex={1} flexDirection={"column"}>
                        <FileSelection onFileChange={onFileChange} register={register} />
                        {previews && previews.length > 0 && <>
                            <Box display="flex" flexDirection="row" >
                                <ConfigurationSelection register={register} configurations={configurations} selectedConfiguration={selectedConfiguration} setSubmitType={setSubmitType} downloadConfigurationAreas={downloadConfigurationAreas} />
                                <ConfigurationSave register={register} configurations={configurations} selectedConfiguration={selectedConfiguration} setSubmitType={setSubmitType} downloadConfigurationAreas={downloadConfigurationAreas} />
                            </Box>
                            <Box height="35vh" overflow="scroll">
                                 <AreaSelection remove={remove} areas={areas} setAreas={setAreas} setValue={setValue} register={register} />
                                <WordSearch wordsToSearch={wordsToSearch} setValue={setValue} setWordsToSearch={setWordsToSearch} addWords={addWords} register={register} />

                            </Box>
                            <Footer reset={reset} setSubmitType={setSubmitType} />
                        </>
                        }
                    </Grid>
                    <Grid height="100%" item xs={7} overflow={'scroll'} display='flex' justifyContent='center'>
                        <Box width="70%" margin={2} display='flex' flexDirection='column'>
                            {previews && previews.length > 0 ? previews.map((preview) => (
                                <AreaSelector
                                    unit='percentage'
                                    areas={areas.filter(a => a.page === preview.page)}
                                    onChange={(areas) => onChangeHandler(areas, preview)}
                                    key={preview.page}
                                    customAreaRenderer={areaInfo}
                                >
                                    <img width="100%" src={preview.type === 'url' ? preview.src : `data:image/jpeg;base64,${preview.src}`} alt="immagine prova" />
                                </AreaSelector>
                            )) :
                                <EmptyPreview previewLoading={previewLoading} />
                            }
                        </Box>
                    </Grid>
                </Grid>
            </form>
            <Snackbar
                open={snackbar.severity === "success" && snackbar.isOpen}
                autoHideDuration={3000}
                onClose={() => hideSnackbar()}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                TransitionComponent={Slide}
            >
                <Alert
                    variant='filled'
                    severity={snackbar.severity}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
            <Snackbar
                open={snackbar.severity === "error" && snackbar.isOpen}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                TransitionComponent={Slide}
            >
                <Alert
                    onClose={() => hideSnackbar()}
                    variant='filled'
                    severity={snackbar.severity}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
            <Modal open={isElaborating} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Card sx={{ padding: 3, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <CircularProgress />
                    <Typography marginLeft={4}>Elaborazione in corso</Typography>
                </Card>
            </Modal>
        </>
    )
}