import { Icon } from "@iconify/react";
import { Card, Grid, IconButton, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { CardHeader } from "./CardHeader.tsx";


export const AreaSelection = ({ areas, setAreas, setValue, remove, register }) => {

    const deleteArea = (index) => {
        let newAreas = [...areas]
        newAreas.splice(index, 1)
        setAreas(newAreas)
    }

    return (
        <Card variant="outlined" sx={{ padding: 2, margin: 2 }} >
            <CardHeader iconName={'fa:square'} title={'Aree selezionate:' + areas.length} />
            {areas && areas.length > 0 ? areas.map((area, i) => {
                return (
                    <Box
                        sx={{
                            '& > :not(style)': { m: 1 },
                            marginTop: 1,
                            display: 'flex'
                        }}
                        key={i}
                        justifyContent='center'
                        alignItems='center'
                    >
                        <TextField key={1} sx={{ display: 'none' }} {...register(`areas.${i}.left`)} name="left" value={area.x / 100} onChange={(e) => {
                            let newAreas = [...areas]
                            newAreas[i].left = e.target.value
                            setAreas(newAreas)
                        }}/>
                        <TextField key={2} sx={{ display: 'none' }} {...register(`areas.${i}.top`)} name="top" value={area.y / 100}  />
                        <TextField key={3} sx={{ display: 'none' }} {...register(`areas.${i}.width`)} name="width" value={area.width /100} />
                        <TextField key={4} sx={{ display: 'none' }} {...register(`areas.${i}.height`)} name="height" value={area.height / 100} />
                        <TextField key={5} sx={{ display: 'none' }} {...register(`areas.${i}.pageNumber`)} name="pageNumber" defaultValue={area.page.toString()} />
                        <TextField key={6} sx={{ display: 'none' }} {...register(`areas.${i}.type`)} name="type" defaultValue={"selection"} />
                        <TextField key={7} sx={{ flex: 8 }} {...register(`areas.${i}.name`)} required label={`Nome area ${i + 1}`} name="name" onChange={(e) => {
                            let newAreas = [...areas]
                            newAreas[i].name = e.target.value
                            setAreas(newAreas)
                        }}
                            value={areas[i].name ? areas[i].name : ""} />
                        <TextField key={8} sx={{ flex: 12 }} {...register(`areas.${i}.word_search`)} label="Parola da cercare" name="word_search" onChange={(e) => {
                            let newAreas = [...areas]
                            newAreas[i].word_search = e.target.value
                            setAreas(newAreas)
                        }}
                            value={areas[i].word_search ? areas[i].word_search : ""} />
                        <TextField key={9} sx={{ flex: 5 }} {...register(`areas.${i}.occurrence`)} label="Occorrenza" name="occurrence" onChange={(e) => {
                            let newAreas = [...areas]
                            newAreas[i].occurrence = e.target.value
                            setAreas(newAreas)
                        }}
                            value={areas[i].occurrence ? areas[i].occurrence : ""} />
                        <TextField key={10} sx={{ flex: 4 }} {...register(`areas.${i}.word_number`)} label={"N° parole"} name="word_number" onChange={(e) => {
                            let newAreas = [...areas]
                            newAreas[i].word_number = e.target.value
                            setAreas(newAreas)
                        }}
                            value={areas[i].word_number ? areas[i].word_number : ""} />
                        <IconButton color="error" sx={{height: 35, width: 35}} onClick={() => deleteArea(i)}>
                            <Icon icon="fa:trash"></Icon>
                        </IconButton>
                    </Box>

                )
            }) : <Typography marginTop={2}>Nessuna area selezionata. Selezionare un'area dall'anteprima.</Typography>}
        </Card>

    )
}

const cardHeaderStyle = {
    paddingY: 1,
    paddingX: 2,
    fontSize: '14px',
    fontFamily: 'sans-serif',
    backgroundColor: '#EEE',
    fontColor: '#000',
    fontWeight: 100
}

const titleProps = {
    fontSize: '15px',
    fontFamily: 'sans-serif',
    fontColor: '#000',
    marginLeft: 1,
    fontWeight: 100,
}
