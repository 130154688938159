import { Icon } from "@iconify/react";
import { Button, Card, MenuItem, Select, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { CardHeader } from "./CardHeader.tsx";


export const ConfigurationSave = ({register,setSubmitType }) => {

    return (
        <Card variant="outlined" sx={{ padding: 2, marginX: 2, flex:1 }}>
            <CardHeader iconName={"fa:download"} title={"Salva nuova configurazione"} />
            <Box display="flex" marginTop={2} flexDirection={"row"}>
                <TextField {...register('configuration_name')} fullWidth name="configuration_name" size="small" />
                <Button sx={{ marginLeft: 2, width: 120 }} color="success" type="submit" onClick={() => { setSubmitType("save-configuration") }}>Salva</Button>
            </Box>
        </Card>
    )
}

const cardHeaderStyle = {
    paddingY: 1,
    paddingX: 2,
    fontSize: '14px',
    fontFamily: 'sans-serif',
    backgroundColor: '#EEE',
    fontColor: '#000',
    fontWeight: 100
}

const titleProps = {
    fontSize: '15px',
    fontFamily: 'sans-serif',
    fontColor: '#000',
    marginLeft: 1,
    fontWeight: 100,
}
